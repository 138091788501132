/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Photo Gallery - Villa Club Tropicana Seychelles"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-p1mx55 --style2 --full pb--60 pt--60" name={"uvod-0"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7ea7ea8b28354c4bbb685e233350055b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mt--20">
              
              <Title className="title-box fs--72" content={"<span style=\"color: rgb(255, 255, 255);\">PHOTO GALLERY</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"18331doow0h"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/8171b2ce51374413a8b1c3f2ec396a91_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/8171b2ce51374413a8b1c3f2ec396a91_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/8171b2ce51374413a8b1c3f2ec396a91_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/8171b2ce51374413a8b1c3f2ec396a91_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana is perched on a lush hillside overlooking two stunning beaches just down the road</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/263c3b4da71f44b885bfec1ecef4ee31_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/263c3b4da71f44b885bfec1ecef4ee31_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/263c3b4da71f44b885bfec1ecef4ee31_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/263c3b4da71f44b885bfec1ecef4ee31_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/263c3b4da71f44b885bfec1ecef4ee31_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/263c3b4da71f44b885bfec1ecef4ee31_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">In Seychelles, most of life unfolds outdoors. Our huge terrace is perfect for this purpose.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/6723009f4c2b43cdbc4f5f0d030aa718_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/6723009f4c2b43cdbc4f5f0d030aa718_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/6723009f4c2b43cdbc4f5f0d030aa718_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/6723009f4c2b43cdbc4f5f0d030aa718_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/6723009f4c2b43cdbc4f5f0d030aa718_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">In Seychelles, most of life unfolds outdoors. Our huge terrace is perfect for this purpose.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/f2e165e52e6a463fa5b82062ef80b1c7_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/f2e165e52e6a463fa5b82062ef80b1c7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/f2e165e52e6a463fa5b82062ef80b1c7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/f2e165e52e6a463fa5b82062ef80b1c7_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">The layout of the two apartments. All rooms facing the ocean!</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rlfn7n6lumq"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/b75c7aa5ddc2482ab711e8d734307634_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/b75c7aa5ddc2482ab711e8d734307634_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/b75c7aa5ddc2482ab711e8d734307634_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/b75c7aa5ddc2482ab711e8d734307634_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/b75c7aa5ddc2482ab711e8d734307634_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/b75c7aa5ddc2482ab711e8d734307634_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Perfect setting for the whole family. The right mix of chilling and fun activities to enjoy together.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/3062bedbf8fa4d6d8813b82e5f3e8f1f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/3062bedbf8fa4d6d8813b82e5f3e8f1f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/3062bedbf8fa4d6d8813b82e5f3e8f1f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/3062bedbf8fa4d6d8813b82e5f3e8f1f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/3062bedbf8fa4d6d8813b82e5f3e8f1f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/3062bedbf8fa4d6d8813b82e5f3e8f1f_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Private infinity swimming pool.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/a981912a78eb46259310fde219e77d60_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/a981912a78eb46259310fde219e77d60_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/a981912a78eb46259310fde219e77d60_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/a981912a78eb46259310fde219e77d60_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/a981912a78eb46259310fde219e77d60_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/a981912a78eb46259310fde219e77d60_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Nothing beats enjoying a glass of champagne with your friends in our private infinity swimming pool.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/cbf2b06c338d412f9ceff64bb3ba380f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/cbf2b06c338d412f9ceff64bb3ba380f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/cbf2b06c338d412f9ceff64bb3ba380f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/cbf2b06c338d412f9ceff64bb3ba380f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/cbf2b06c338d412f9ceff64bb3ba380f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/cbf2b06c338d412f9ceff64bb3ba380f_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Vista apartment upstairs. Granite apartment downstairs. Every room, everywhere you are you are always looking out at the ocean at Villa Club Tropicana.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"s67jw8ndwmk"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"quclqvmhk0d"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/14f8fe93d4754298a59cd8fc9d5ce8d9_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/14f8fe93d4754298a59cd8fc9d5ce8d9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/14f8fe93d4754298a59cd8fc9d5ce8d9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/14f8fe93d4754298a59cd8fc9d5ce8d9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/14f8fe93d4754298a59cd8fc9d5ce8d9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/14f8fe93d4754298a59cd8fc9d5ce8d9_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Vista apartment - living room opening onto the generous terrace with infinity pool</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/8cbfc8769511481f9e1f3d1fcc37ebbe_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/8cbfc8769511481f9e1f3d1fcc37ebbe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/8cbfc8769511481f9e1f3d1fcc37ebbe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/8cbfc8769511481f9e1f3d1fcc37ebbe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/8cbfc8769511481f9e1f3d1fcc37ebbe_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Healthy breakfast from our garden enjoyed with the best view in the world<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/181e62ebb2f2417eab1cefd3cbe6dfd9_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/181e62ebb2f2417eab1cefd3cbe6dfd9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/181e62ebb2f2417eab1cefd3cbe6dfd9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/181e62ebb2f2417eab1cefd3cbe6dfd9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/181e62ebb2f2417eab1cefd3cbe6dfd9_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Our infinity pool overlooking vast expanses of Indian Ocean - at full moon</span><br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/2aeb673952244893bd35983a6fe896c9_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/2aeb673952244893bd35983a6fe896c9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/2aeb673952244893bd35983a6fe896c9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/2aeb673952244893bd35983a6fe896c9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/2aeb673952244893bd35983a6fe896c9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/2aeb673952244893bd35983a6fe896c9_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Facing 90 degrees east, we enjoy fabulous 220 degree views at the Villa</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"so3zfru6dgt"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/394338be6f6546a7a9be0463f5041f56_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/394338be6f6546a7a9be0463f5041f56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/394338be6f6546a7a9be0463f5041f56_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/394338be6f6546a7a9be0463f5041f56_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/394338be6f6546a7a9be0463f5041f56_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/394338be6f6546a7a9be0463f5041f56_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Vista apartment - one of the two en-suite bedrooms</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/dd6f01222c554123a2bd964d85621a8f_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/dd6f01222c554123a2bd964d85621a8f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/dd6f01222c554123a2bd964d85621a8f_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Breakfast is served - fruits grown in our garden</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/184e2a971fd64d57a68438deb7387ae4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/184e2a971fd64d57a68438deb7387ae4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/184e2a971fd64d57a68438deb7387ae4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/184e2a971fd64d57a68438deb7387ae4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/184e2a971fd64d57a68438deb7387ae4_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Endless vistas of different hues of blue</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/b4f67d802d704891b7d369ddbdd6c9d2_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/b4f67d802d704891b7d369ddbdd6c9d2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/b4f67d802d704891b7d369ddbdd6c9d2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/b4f67d802d704891b7d369ddbdd6c9d2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/b4f67d802d704891b7d369ddbdd6c9d2_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Vista apartment - one of the two en-suite bedrooms</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"i3f9hbd6j5t"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/f2d928cadb5d4e779a65fcd899b06281_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/f2d928cadb5d4e779a65fcd899b06281_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/f2d928cadb5d4e779a65fcd899b06281_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/f2d928cadb5d4e779a65fcd899b06281_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/f2d928cadb5d4e779a65fcd899b06281_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/f2d928cadb5d4e779a65fcd899b06281_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Our locally sourced hand-carved house sign. Local art.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/55674510c39b4517b1467113bdd3d478_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/55674510c39b4517b1467113bdd3d478_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/55674510c39b4517b1467113bdd3d478_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/55674510c39b4517b1467113bdd3d478_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/55674510c39b4517b1467113bdd3d478_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Grill your own fresh fish on the outdoor grill which is here for you to enjoy.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/af473d49babd42328b7132b991f65d22_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/af473d49babd42328b7132b991f65d22_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/af473d49babd42328b7132b991f65d22_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/af473d49babd42328b7132b991f65d22_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/af473d49babd42328b7132b991f65d22_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Working is not really working from Villa Club Tropicana, Seychelles</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/718f5a1c0fca4587b5de596fbb1ed298_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/718f5a1c0fca4587b5de596fbb1ed298_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/718f5a1c0fca4587b5de596fbb1ed298_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/718f5a1c0fca4587b5de596fbb1ed298_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/718f5a1c0fca4587b5de596fbb1ed298_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/718f5a1c0fca4587b5de596fbb1ed298_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">The Granite apartment - generous bedroom with bunkbed, ideal for families. Features natural granite rocks incorporated into the layout.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"8pseznxbvb6"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/1f7a77ddc7654794a44e7ea4d167668e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/1f7a77ddc7654794a44e7ea4d167668e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/1f7a77ddc7654794a44e7ea4d167668e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/1f7a77ddc7654794a44e7ea4d167668e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/1f7a77ddc7654794a44e7ea4d167668e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/1f7a77ddc7654794a44e7ea4d167668e_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Granite apartment - living-room and kitchen boasting real granite rocks as part of the interior decor.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/548d43e3e02e4d9d82f58aa97aa80962_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/548d43e3e02e4d9d82f58aa97aa80962_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/548d43e3e02e4d9d82f58aa97aa80962_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/548d43e3e02e4d9d82f58aa97aa80962_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/548d43e3e02e4d9d82f58aa97aa80962_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/548d43e3e02e4d9d82f58aa97aa80962_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">The lounge sitting area on the terrace for the ultimate chill.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/9c14122b3f4b41cea3f146a57215440e_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/9c14122b3f4b41cea3f146a57215440e_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Morning coffee served with that view...</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/fa4ef56b86f14f40856fe9894b26f0ae_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/fa4ef56b86f14f40856fe9894b26f0ae_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36311/fa4ef56b86f14f40856fe9894b26f0ae_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36311/fa4ef56b86f14f40856fe9894b26f0ae_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36311/fa4ef56b86f14f40856fe9894b26f0ae_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Vista apartment - one of the two bathrooms available to you in this apartment.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"d2gwhu249g"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/68d826622f0e410b8d84a526fc521b47_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/68d826622f0e410b8d84a526fc521b47_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/68d826622f0e410b8d84a526fc521b47_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/68d826622f0e410b8d84a526fc521b47_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Frangipani. Some of the troical flowers grown in our garden.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/fac68c0a053547b8a7120dd043c85d75_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/fac68c0a053547b8a7120dd043c85d75_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/fac68c0a053547b8a7120dd043c85d75_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/fac68c0a053547b8a7120dd043c85d75_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">90 degrees East location ensures the best sunrises for all the morning lovers.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/bfcf39c2818f41138553ef7cf47969a2_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/bfcf39c2818f41138553ef7cf47969a2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/bfcf39c2818f41138553ef7cf47969a2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/bfcf39c2818f41138553ef7cf47969a2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/bfcf39c2818f41138553ef7cf47969a2_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Sunbathing and meditating...</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/428d1fe3baf247bdb226987eaa95f4ad_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/428d1fe3baf247bdb226987eaa95f4ad_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36311/428d1fe3baf247bdb226987eaa95f4ad_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36311/428d1fe3baf247bdb226987eaa95f4ad_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36311/428d1fe3baf247bdb226987eaa95f4ad_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/36311/428d1fe3baf247bdb226987eaa95f4ad_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">There is badminton, table tennis and a grill at our disposition i the garden.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"m9gldr519oc"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/bf58e7c46382454abde2d920c2ea83ef_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/bf58e7c46382454abde2d920c2ea83ef_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/bf58e7c46382454abde2d920c2ea83ef_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/bf58e7c46382454abde2d920c2ea83ef_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/bf58e7c46382454abde2d920c2ea83ef_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/bf58e7c46382454abde2d920c2ea83ef_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Bird`s eye view of Villa Club Tropicana nestled in a lush tropical hillside<br>taken from the airplane<br></span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/3670ef79734a488196e91680cbb62e67_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/3670ef79734a488196e91680cbb62e67_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36311/3670ef79734a488196e91680cbb62e67_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36311/3670ef79734a488196e91680cbb62e67_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36311/3670ef79734a488196e91680cbb62e67_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/36311/3670ef79734a488196e91680cbb62e67_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Granite apartment offers a bunk bed too which is very popular with our little guests</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/9b7802a6d8f349449d7f96e789d61956_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/9b7802a6d8f349449d7f96e789d61956_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/9b7802a6d8f349449d7f96e789d61956_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/9b7802a6d8f349449d7f96e789d61956_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">Fresh tropical fruits available daily from stalls and markets around the island</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/0f29bb0784cc4e5890a5a8b905f81866_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/0f29bb0784cc4e5890a5a8b905f81866_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/0f29bb0784cc4e5890a5a8b905f81866_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2--15);\">North East Point beach just down the road from Villa Club Tropicana</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"438aa2affao"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/255772251c4b43c082d6d1774eec34ce_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/255772251c4b43c082d6d1774eec34ce_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/255772251c4b43c082d6d1774eec34ce_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/255772251c4b43c082d6d1774eec34ce_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Being a self-catering villa, a laundry area is a must.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/395a94a63c094d94b1609cdd54229788_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/395a94a63c094d94b1609cdd54229788_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/395a94a63c094d94b1609cdd54229788_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/395a94a63c094d94b1609cdd54229788_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/395a94a63c094d94b1609cdd54229788_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">The very rare and strictly protected Seychelles Kestrel. He too chose Villa Club Tropicana for his home. He comes at dusk and leaves with the first rays of sun. Sometimes on hit days he will come and have a bath,</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/ade1de71d4bb4ad2988a7b2186599858_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/ade1de71d4bb4ad2988a7b2186599858_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/ade1de71d4bb4ad2988a7b2186599858_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/ade1de71d4bb4ad2988a7b2186599858_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/ade1de71d4bb4ad2988a7b2186599858_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">These gentle giants can be seen in many places around the island.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/5a752bfe2ceb4afe9ccbdfe0cdeb17c1_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/5a752bfe2ceb4afe9ccbdfe0cdeb17c1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/5a752bfe2ceb4afe9ccbdfe0cdeb17c1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/5a752bfe2ceb4afe9ccbdfe0cdeb17c1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/5a752bfe2ceb4afe9ccbdfe0cdeb17c1_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Sir Selwyn Selwyn market in Victoria. Here you can buy all your fresh local produce - fish, fruits, vegetables, flowers, spices and also some souvenirs.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fqyilic116"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/1600c785a8644b079c832481a1351f1e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/1600c785a8644b079c832481a1351f1e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/1600c785a8644b079c832481a1351f1e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/1600c785a8644b079c832481a1351f1e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/1600c785a8644b079c832481a1351f1e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36311/1600c785a8644b079c832481a1351f1e_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Carana Beach - one of the two gorgeous beaches just down the road from us. This one is famous for its amazing granite boulders.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/055603aeab4543ce86b29d853ee86e40_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/055603aeab4543ce86b29d853ee86e40_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/055603aeab4543ce86b29d853ee86e40_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/055603aeab4543ce86b29d853ee86e40_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/055603aeab4543ce86b29d853ee86e40_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">The private &nbsp;infinity swimming pol overlooks the St. Anne national Marine Park as well as the islands of Praslin, La Digue, Fregate and Aride.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/ee3a0689da414c1aac6f3d69a7e15993_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/ee3a0689da414c1aac6f3d69a7e15993_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/ee3a0689da414c1aac6f3d69a7e15993_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/ee3a0689da414c1aac6f3d69a7e15993_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/ee3a0689da414c1aac6f3d69a7e15993_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Kids and adults alike love chilling in our amazing, large, private infinity swimming pool offering some of the best views on the island.</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36311/4e57c71b70b440a78c525dc16170f86a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36311/4e57c71b70b440a78c525dc16170f86a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36311/4e57c71b70b440a78c525dc16170f86a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36311/4e57c71b70b440a78c525dc16170f86a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36311/4e57c71b70b440a78c525dc16170f86a_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: var(--color-custom-2);\">Its all about that view at Villa Club Tropicana</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--2" anim={""} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":449}}>
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: var(--color-custom-2);\">VILLA CLUB TROPICANA CARANA<br>SEYCHELLES</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2--15);\">Holiday self-catering rental. Your unique opportunity to enjoy Seychelles in true `island style.`</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"<span style=\"color: var(--color-custom-2--15);\">Upper Carana, Glacis District, Mahé, Seychelles<br>Business Registration number: B8435640<br>Licence number: 324496<br>&nbsp;info@villaclubtropicana.com\n<br>+420 737466348</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}